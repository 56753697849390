export default {
  path: 'document',
  children: [
    {
      path: '',
      name: 'retail.document',
      component: () => import('./list/DocumentListPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'retail.document.edit',
      component: () => import('./form/DocumentFormPage.vue')
    }
  ]
}
