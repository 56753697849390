export default {
  path: 'product-template',
  children: [
    {
      path: '',
      name: 'retail.product-template',
      component: () => import('./list/ProductTemplateListPage.vue')
    },
    {
      path: 'add',
      name: 'retail.product-template.add',
      component: () => import('./form/ProductTemplateFormPage.vue')
    },
    {
      path: ':uuid',
      name: 'retail.product-template.detail',
      component: () => import('./detail/ProductTemplateDetailPage.vue')
    }
    // {
    //   path: ':uuid',
    //   name: 'retail.product-template.detail',
    //   component: () => import('./detail/ProductTemplateDetailPage.vue')
    // }
  ]
}
